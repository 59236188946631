exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-certifications-tsx": () => import("./../../../src/pages/certifications.tsx" /* webpackChunkName: "component---src-pages-certifications-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-psa-certifications-tsx": () => import("./../../../src/pages/psa/certifications.tsx" /* webpackChunkName: "component---src-pages-psa-certifications-tsx" */),
  "component---src-pages-psa-responsible-gaming-tsx": () => import("./../../../src/pages/psa/responsible-gaming.tsx" /* webpackChunkName: "component---src-pages-psa-responsible-gaming-tsx" */),
  "component---src-pages-psa-wifi-tsx": () => import("./../../../src/pages/psa/wifi.tsx" /* webpackChunkName: "component---src-pages-psa-wifi-tsx" */),
  "component---src-pages-responsible-gaming-tsx": () => import("./../../../src/pages/responsible-gaming.tsx" /* webpackChunkName: "component---src-pages-responsible-gaming-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/BlogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

